<template>
    <div class="nav">
        <div class="nav-wrapper">
            <div class="nav-inner">
                <a class="nav-logo">
                    <img :src="webp(require(`../../../public/img/corporat/logo-koikeya.png`))" alt="Koikeya">
                </a>
                <div class="nav-btn">
                    <div class="nav-btn__inner" :class="{active: menuOpen}" @click="menuOpen = !menuOpen">
                        <span></span><span></span>
                    </div>
                </div>
            </div>
        </div>

        <div class="nav-content" :class="{open: menuOpen}">
            <div class="nav-content__wrapper">
                <div class="nav-content__inner">
                    <div class="nav-content__list">
                        <div class="nav-content__item fadeup"><a href="/" class="nav-content__link">{{ $t(`navigation.home`) }}</a></div>
                        <div class="nav-content__item fadeup"><a href="/corporate/" class="nav-content__link" :class="{active: page==='corporate'}">{{ $t(`navigation.corporate`) }}</a></div>
                        <div class="nav-content__item fadeup"><a href="/product/karamucho" class="nav-content__link" :class="{active: page==='karamucho'}">KARAMUCHO</a></div>
                        <div class="nav-content__item fadeup"><a href="/product/koimucho" class="nav-content__link" :class="{active: page==='koimucho'}">KoiMUCHO</a></div>
                        <div class="nav-content__logo fadeup">
                            <a href="/product/karamucho" class="nav-content__logo__karamocho">
                                <img :src="webp(require(`../../../public/img/menu-logo-karamucho.png`))" alt="karamucho">
                            </a>
                            <a href="/product/koimucho" class="nav-content__logo__koimocho">
                                <img :src="webp(require(`../../../public/img/menu-logo-koimucho.png`))" alt="koimucho">
                            </a>
                        </div>
                        <div class="nav-content__social fadeup">
                            <a href="https://www.facebook.com/Karamucho.Vn/" class="nav-content__social__item" target="_blank" rel= "noreferrer noopener">
                                <img :src="webp(require(`../../../public/img/icon/icon-facebook.png`))" alt="facebook">
                            </a>
                            <a href="https://www.youtube.com/channel/UCUNnWTLL2CyNMmF2dpbd9xg" class="nav-content__social__item" target="_blank" rel= "noreferrer noopener">
                                <img :src="webp(require(`../../../public/img/icon/icon-youtube.png`))" alt="youtube">
                            </a>
                            <a href="javascript:void(0)" class="nav-content__social__item language" @click="changeLocale(switchedLocale)">{{ switchedLocale.toUpperCase() }}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div id="nav-scroll" class="nav-scroll">
            <div class="nav-scroll__wrapper">
                <div class="nav-scroll__inner">
                    <div class="nav-scroll__list">
                        <div class="nav-scroll__item corporate" :class="$i18n.locale"><a href="#" class="nav-scroll__link active">{{ $t(`navigation.corporate`) }}</a></div>
                        <div class="nav-scroll__item" v-for="(navItem, index) in navItems" :key="index">
                            <a href="javascript:void(0)"
                               class="nav-scroll__link small"
                               :data-anchor="index"
                               :class="{active: num===index}"
                               @click="num = index"
                               v-scroll-to="navItem.anchor"
                            >{{$i18n.locale == 'vi' ? navItem.name_vi : navItem.name_en }}</a>
                        </div>
                        <div class="nav-scroll__logo">
                            <a href="/product/karamucho" class="nav-scroll__logo__karamocho">
                                <img :src="webp(require(`../../../public/img/menu-logo-karamucho.png`))" alt="karamucho">
                            </a>
                            <a href="/product/koimucho" class="nav-scroll__logo__koimocho">
                                <img :src="webp(require(`../../../public/img/menu-logo-koimucho.png`))" alt="koimucho">
                            </a>
                        </div>
                        <ul class="nav-scroll__social">
                            <li class="nav-scroll__social__item">
                                <a href="https://www.facebook.com/Karamucho.Vn/">
                                    <img :src="webp(require(`../../../public/img/icon/icon-facebook-black.png`))" alt="facebook">
                                </a>
                            </li>
                            <li class="nav-scroll__social__item">
                                <a href="https://www.youtube.com/channel/UCUNnWTLL2CyNMmF2dpbd9xg">
                                    <img :src="webp(require(`../../../public/img/icon/icon-youtube-black.png`))" alt="youtube">
                                </a>
                            </li>
                            <li class="nav-scroll__social__item language"><a href="javascript:void(0)" @click="changeLocale(switchedLocale)">{{ switchedLocale.toUpperCase() }}</a></li>
                        </ul>
                        <div class="scroll-down" :class="$i18n.locale"><a href="javascript:void(0)">{{ $t('scroll down') }}</a></div>
                    </div>
                </div><!--/ .nav-scroll__inner -->
            </div><!--/ .nav-scroll__wrapper -->
        </div><!--/ .nav-scroll -->
    </div>
</template>

<script>
    export default {
        name: 'Navigation',
        props: {
            num: Number,
            locale: String
        },
        data() {
            return {
                page: 'corporate',
                menuOpen: false,
                screenWidth: 0,
                dataEl: null,
                navItems: [
                    {
                        name: 'Top',
                        anchor: '#top'
                    },
                    {
                        name_en: 'Brand message',
                        name_vi: 'Thông điệp thương hiệu',
                        anchor: '#brand-message'
                    },
                    {
                        name_en: 'Company Information',
                        name_vi: 'Thông tin Công ty',
                        anchor: '#company-info'
                    },
                    {
                        name_en: 'Other',
                        name_vi: 'Quá trình phát triển',
                        anchor: '#other'
                    },
                    {
                        name_en: 'News',
                        name_vi: 'Tin tức',
                        anchor: '#news'
                    },
                    {
                        name_en: 'Contact Information',
                        name_vi: 'Liên hệ',
                        anchor: '#contact'
                    }
                ]
            }
        },
        computed: {
            switchedLocale() {
                return this.locale === 'vi' ? 'en' : 'vi'
            }
        },
        watch: {
            menuOpen: function () {
                let html = document.querySelector('html');
                if (this.menuOpen) {
                    html.classList.add('menu-open')
                } else {
                    html.classList.remove('menu-open')
                }
            }
        },
        methods: {
            changeLocale(locale) {
                this.$emit('changeLocale', locale)
                this.menuOpen = false
            }
        },
        mounted() {
            this.screenWidth = window.outerWidth;
        }

    }
</script>

<style lang="scss" scoped>
    @import '../../assets/scss/mixin/_mixin';

    $sm: 768;
    $md: 992;
    $lg: 1200;

    .nav {
        position: fixed;
        top: 0;
        right: 0;
        width: 100%;
        z-index: 9;
        transition: all .2s ease-in-out;
        @media screen and (min-width: 1200px) {
            width: 245px;
            height: 100%;
            border-left: 1px solid #dadada;
        }

        &-wrapper {
            padding: 15px 15px 20px;
            background: #000 url("../../../public/img/corporat/bg/section-contact__wrapperX.jpg") repeat-x left bottom / auto 15px;
            @include media(set-min-w, $md) {
                background-size: auto;
                padding-bottom: 30px;
            }
        }

        &-inner {
            display: flex;
            align-items: center;
            justify-content: space-between;
            @include media(set-min-w, $md) {
                height: 69px;
            }
            @include media(set-min-w, $lg) {
                justify-content: flex-end;
            }
        }

        &-logo {
            display: block;
            @include media(set-min-w, $lg) {
                display: none;
            }

            img {
                max-height: 50px;
                @include media(set-min-w, $sm) {
                    max-height: 68px;
                }
                @include media(set-min-w, $lg) {
                    max-height: none;
                }
            }
        }

        &-content {
            overflow: hidden;
            position: fixed;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 2px;
            height: 0;
            background: #000000;
            z-index: -1;
            transition: width .3s cubic-bezier(0.165, 0.84, 0.44, 1), height .3s .3s cubic-bezier(0.165, 0.84, 0.44, 1);

            &.open {
                transition: width .4s .3s cubic-bezier(0.165, 0.84, 0.44, 1), height .3s cubic-bezier(0.165, 0.84, 0.44, 1);
                width: 101%;
                height: 101%;
                z-index: 999;

                .fadeup {
                    opacity: 1;
                    transform: translateY(0);
                    transition: .4s ease;

                    @for $i from 1 through 10 {
                        &:nth-child(#{$i}) {
                            transition-delay: (0.1s * $i) + .6s
                        }
                    }
                }
            }

            &__wrapper {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 100%;
                //width: 600px;
                max-width: 100%;
                padding: 0 2rem;
                margin: 0 auto;
            }

            &__inner {
                text-align: center;
            }

            &__item {
                padding: .5rem 0;
                opacity: 0;
                transform: translateY(30px);

            }

            &__link {
                position: relative;
                font-size: 5.6rem;
                font-weight: 400;
                color: #6d6d6d;
                //letter-spacing: 2px;
                text-transform: capitalize;

                &:after {
                    content: "";
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    height: 2px;
                    width: 0;
                    transition: .4s ease-in-out;
                    background: #ffffff;
                }

                &:hover {
                    &:after {
                        width: 100%;

                        @include media(phone) {
                            display: none
                        }
                    }
                }

                @include media(set-max-w, 1440) {
                    font-size: 4.5rem;
                }
                @include media(set-max-w, 1366) {
                    font-size: 4rem;
                }
                @include media(phone) {
                    font-size: 3rem;
                }

                &.active {
                    color: #fff;
                    font-weight: 700;
                }
            }

            &__logo {
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 2rem -3.5rem 0;
                opacity: 0;
                transform: translateY(30px);

                &__karamocho,
                &__koimocho {
                    width: 50%;
                    padding: 0 3.5rem;

                    @include media(set-max-w, 1440) {
                        width: 45%;
                    }
                    @include media(set-max-w, 1366) {
                        width: 40%;
                    }
                    @include media(phone) {
                        width: 40%;
                    }
                }
            }

            &__social {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 3rem;
                opacity: 0;
                transform: translateY(30px);

                &__item {
                    padding: 0 4.5rem;

                    @include media(phone) {
                        padding: 0 3rem
                    }
                }

                .language {
                    color: #cacaca;
                    font-size: 2rem;
                    font-weight: 700;
                    text-transform: uppercase;
                    text-decoration: underline;
                }
            }
        }

        &-btn {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 10;

            .menu-open & {
                z-index: 9999;
            }

            &.fixed {
                position: fixed;
                top: 20px;
                right: 6.5rem;
            }

            &__inner {
                position: relative;
                width: 4.4rem;
                height: 4.4rem;
                cursor: pointer;

                &:hover {
                    span {
                        &:last-child {
                            width: 100%;
                        }
                    }
                }

                &.active {
                    span {
                        transition: .4s .4s cubic-bezier(.165, .84, .44, 1), width .4s cubic-bezier(.165, .84, .44, 1);

                        &:first-child {
                            top: 22px;
                            transform: rotate(45deg)
                        }

                        &:last-child {
                            top: 22px;
                            transform: rotate(135deg);
                            width: 100%;
                        }
                    }
                }

                span {
                    position: absolute;
                    right: 0;
                    height: 3px;
                    width: 100%;
                    background: #fff;
                    transition: .4s cubic-bezier(.165, .84, .44, 1), width .4s .4s cubic-bezier(.165, .84, .44, 1);

                    &:first-child {
                        top: 18px
                    }

                    &:last-child {
                        top: 26px;
                        width: 60%;
                    }
                }
            }
        }

        .nav-scroll {
            display: none;
            @include media(set-min-w, $lg) {
                display: block;
                height: calc(100vh - 114px);
                height: -moz-calc(100vh - 114px);
                height: -webkit-calc(100vh - 114px);
            }

            .corporate {
                height: 81px;
                margin-right: -10px;
                background: url("../../../public/img/corporat/icon-corp-vi.png") no-repeat right top / contain;
                &.en {
                    background: url("../../../public/img/corporat/icon-corp-en.png") no-repeat right top / contain;
                }

                .nav-scroll__link {
                    @include media(set-min-w, $lg) {
                        display: none;
                    }
                }
            }

            .scroll-down {
                position: absolute;
                bottom: 120px;
                left: -60px;
                &.en {
                    left: -30px;
                    bottom: 80px;
                }

                a {
                    display: block;
                    padding-right: 54px;
                    background: url("../../../public/img/icon/icon-arrow-half-scroll.png") no-repeat right center;
                    transform: rotate(90deg);
                    color: #000;
                    font-size: 1.4rem;
                    font-weight: 600;
                    opacity: 1;
                    animation: sdb05 3s infinite;
                }
            }

            &__wrapper {
                position: relative;
                width: 100%;
                height: 100%;
                padding: 2rem 1rem;
                margin: 0 auto;
                overflow: hidden;
            }

            &__inner {
                display: flex;
                justify-content: flex-end;
                width: 100%;
                height: 100%;
                padding-top: 10%;
                text-align: right;

                @include media(set-max-w, 1440) {
                    padding-top: 0;
                }
            }

            &__item {
                padding: .5rem 0;
            }

            &__link {
                position: relative;
                color: #393939;
                font-size: 1.8rem;
                font-weight: 700;

                &:hover::after {
                    width: 100%;
                }
                &::after {
                    content: "";
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    width: 0;
                    height: 2px;
                    transition: .4s ease-in-out;
                    background-color: #6d6d6d;
                }
                &.active {
                    color: #cc0f04;
                    font-weight: 700;

                    &::after {
                        background-color: #cc0f04;
                    }
                }
                @include media(set-max-w, 1440) {
                    font-size: 1.6rem;
                }
            }

            &__logo {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                padding-top: 30px;

                @include media(set-max-w, 1440) {
                    padding-top: 1.5rem;
                }
                &__karamocho,
                &__koimocho {
                    width: 50%;
                    max-width: 88px;
                    margin-top: 15px;
                    margin-bottom: 15px;

                    @include media(set-max-w, 1440) {
                        margin: 1rem 0;
                    }
                }
            }

            &__social {
                position: absolute;
                right: 20px;
                bottom: 10px;
                text-align: center;

                @include media(set-max-w, 1440) {
                    bottom: 0
                }
                @include media(set-max-h, 850) {
                    right: 130px;
                }
                &__item {
                    padding: 0;
                    margin-top: 20px;
                    margin-bottom: 20px;
                }

                .language a {
                    position: relative;
                    display: block;
                    color: #333033;
                    font-size: 1.8rem;
                    font-weight: 800;
                    text-align: center;
                    text-transform: uppercase;
                }
            }
        }
    }
    .webp {
        .nav {
            &-wrapper {
                background: #000 url("../../../public/img/corporat/bg/section-contact__wrapperX.webp") repeat-x left bottom / auto 15px;
            }
            .nav-scroll {
                .corporate {
                    background: url("../../../public/img/corporat/icon-corp-vi.webp") no-repeat right top / contain;
                    &.en {
                        background: url("../../../public/img/corporat/icon-corp-en.webp") no-repeat right top / contain;
                    }
                }
                .scroll-down {
                    a {
                        background: url("../../../public/img/icon/icon-arrow-half-scroll.webp") no-repeat right center;
                    }
                }
            }
        }
    }
    @keyframes sdb05 {
        0% {
            transform: rotate(90deg) translate(0, 0);
            opacity: 0;
        }
        10% {
            opacity: 1;
        }
        90% {
            opacity: 1;
        }
        100% {
            transform: rotate(90deg) translate(20px, 0);
            opacity: 0;
        }
    }
</style>